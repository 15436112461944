import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="inner">
      <div className="common-header">
        <h1>ページが見つかりません</h1>
        <p className="subtitle">404 Not Found</p>
      </div>
      {/* /.common-header */}
      <p className="">
        お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。
        <br />
        また、URL、ファイル名にタイプミスがないか再度ご確認ください。
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
